<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-loading="isLoading" v-if="formAction === 'select'">
      <div class="titles">{{ __("Subject") }}</div>
      <div class="details">{{ contentFormInfo.notification_subject }}</div>
      <div class="titles">{{ __("Recipients") }}</div>
      <div class="details">{{ contentFormInfo.target_user_group }}</div>
      <div class="titles">{{ __("Notification period") }}</div>
      <div class="details">
        {{ formattedDateTime(contentFormInfo.start_date)
        }}<span style="padding-left: 5px; padding-right: 5px">{{
          __("to")
        }}</span
        >{{ formattedDateTime(contentFormInfo.end_date) }}
      </div>
      <div class="titles">{{ __("Status") }}</div>
      <div class="details">
        {{
          __("Sent on :date", {
            date: formattedDateTime(contentFormInfo.created_at)
          })
        }}
      </div>

      <div style="margin-top: 20px;">
        <el-dropdown
          @command="command => handleAction(command, contentFormInfo)"
          placement="bottom-start"
        >
          <el-button plain>{{ __("Actions") }}</el-button>
          <el-dropdown-menu slot="dropdown" style="width: 200px;">
            <el-dropdown-item command="edit" :disabled="!this.canWrite()">
              <img
                class="action-icon"
                :src="require('@/assets/icons/icon-edit.svg')"
              />
              <span>{{ __("Edit") }}</span>
            </el-dropdown-item>
            <el-dropdown-item
              command="delete"
              v-if="showDeleteOption(contentFormInfo.publisher_id)"
              :disabled="!this.canWrite()"
            >
              <img
                class="action-icon"
                :src="require('@/assets/icons/icon-delete.svg')"
              />
              <span>{{ __("Delete") }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      :show-close="false"
      top="6vh"
      destroy-on-close
      fullscreen
    >
      <div class="notification-form-dialog">
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header :title="__('Notification')" :contentId="id" />
          </el-col>
        </el-row>
        <el-scrollbar :native="false">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Recipients')" prop="recipients">
                  <el-cascader
                    v-model="contentForm.recipients"
                    style="width: 100%"
                    :options="groupedRecipientOptions"
                    :props="recipient_props"
                    clearable
                    filterable
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Subject')"
                  prop="notification_subject"
                >
                  <el-input
                    v-model="contentForm.notification_subject"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Body')" prop="content">
                  <vue-editor
                    class="ql-container"
                    v-model="contentForm.content"
                    :editorToolbar="customToolbar"
                    @blur="contentBlur"
                  ></vue-editor>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  :label="__('Notification period')"
                  prop="notification_period"
                >
                  <el-date-picker
                    style="width: 100%"
                    v-model="notificationPeriod"
                    type="datetimerange"
                    :range-separator="__('To')"
                    :start-placeholder="__('Start date')"
                    :end-placeholder="__('End date')"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :format="displayDateTimeSecondsFormat"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item>
                  <save-button
                    type="primary"
                    :loading="isLoading"
                    @click="submitForm"
                    class="submitBtn"
                    :primaryKey="id"
                    variant="CreateUpdate"
                    :disabled="!this.canWrite()"
                  />
                  <el-button class="cancelBtn" @click="cancelForm"
                    >{{ __("Cancel") }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { mapState, mapActions, mapGetters } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { EventBus } from "@/EventBus";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import { VueEditor } from "vue2-editor";
import SaveButton from "@/components/SaveButton";
import _ from "lodash";
import moment from "moment-timezone";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    VueEditor,
    SaveButton
  },
  props: {
    notification: {
      type: Object,
      required: false,
      default: () => ({
        notification_subject: "",
        content: "",
        target_user_group: "all_users",
        start_date: "",
        end_date: "",
        recipients: []
      })
    },

    id: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  data() {
    const validateNotificationPeriod = (rule, value, callback) => {
      let currentPeriod = this.notificationPeriod;
      if (_.isEmpty(currentPeriod[0]) && _.isEmpty(currentPeriod[1])) {
        callback(__("Start date time and End date time cannot be empty"));
      } else if (_.isEmpty(currentPeriod[0])) {
        callback(__("Start date time cannot be empty"));
      } else if (_.isEmpty(currentPeriod[1])) {
        callback(__("End date time cannot be empty"));
      } else if (moment(currentPeriod[0]).isBefore(moment())) {
        callback(
          __("Start date time needs to be greater than current date time")
        );
      } else if (moment(currentPeriod[1]).isBefore(moment(currentPeriod[0]))) {
        callback(
          __("End date time needs to be greater than the current date time")
        );
      } else {
        callback();
      }
    };
    return {
      rules: {
        recipients: [
          {
            required: true,
            trigger: "blur",
            message: __("Recipients is required")
          }
        ],
        notification_subject: [
          {
            required: true,
            trigger: "blur",
            message: __("Subject is required")
          }
        ],
        content: [
          {
            required: true,
            trigger: "blur",
            message: __("Content is required")
          }
        ],
        notification_period: [
          {
            required: true,
            validator: validateNotificationPeriod,
            trigger: "change"
          }
        ]
      },
      notificationPeriod: ["", ""],
      notificationFormKey: 0,
      isSubmitting: false,
      recipient_props: { multiple: true },
      recipient_options: [],
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        // ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"],
        ["clean"] // remove formatting button
      ],
      pickerOptions: {
        disabledDate: this.disabledDate
      }
    };
  },

  computed: {
    ...mapState("notifications", {
      lastNotification: state => state.lastNotification,
      isLoading: state => state.isLoading
    }),
    ...mapState("users", {
      users: state => state.users,
      loading: state => state.isLoading
    }),

    ...mapGetters("app", {
      displayDateTimeSecondsFormat: "displayDateTimeSecondsFormat",
      formattedDateTime: "formattedDateTime"
    }),

    groupedRecipientOptions() {
      let recipient_options = [
        {
          label: __("Account Users"),
          value: "Account Users",
          children: []
        }
      ];

      this.users.map(user => {
        if (user.roles.data.length > 0) {
          if (
            user.roles.data[0].scope === "ac" &&
            this.$auth.user().user_id !== user.user_id
          ) {
            recipient_options[0].children.push({
              label: user.email,
              value: user.user_id
            });
          }
        }
      });
      return recipient_options;
    }
  },

  methods: {
    ...mapActions("notifications", {
      createNotification: "createNotification",
      updateUserNotification: "updateUserNotification",
      deleteNotification: "deleteNotification"
    }),

    ...mapActions("users", {
      getUsers: "getUsers"
    }),
    disabledDate(time) {
      return moment(time).isSameOrBefore(moment().subtract(1, "days"));
    },
    handleAction(command, contentFormInfo) {
      if (command === "edit") {
        this.handleEdit();
      } else if (command === "delete") {
        this.deleteSpNotification(contentFormInfo);
      }
    },

    showDeleteOption(publisher_id) {
      return publisher_id === this.$auth.user().user_id;
    },

    contentBlur() {
      this.$refs.contentForm.validateField("content");
    },

    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1
              ? this.createNotification
              : this.updateUserNotification;

          this.contentForm.start_date = this.notificationPeriod[0];
          this.contentForm.end_date = this.notificationPeriod[1];
          if (
            this.lastNotification.content === this.contentForm.content &&
            this.contentForm.recipients.length > 0
          ) {
            this.$confirm(
              __("The last notification sent had the same content. Continue?"),
              __("Warning"),
              {
                confirmButtonText: __("OK"),
                cancelButtonText: __("Cancel"),
                type: "warning"
              }
            ).then(() => {
              this.submit(process);
            });
          } else {
            this.submit(process);
          }
        }
      });
    },

    submit(process) {
      process(this.contentForm)
        .then(data => {
          this.contentFormKey = this.contentFormKey + !this.id ? 0 : 1;
          // hack to refresh the user form to persist recent modifications
          this.isSubmitting = false;

          this.id === -1
            ? this.$message({
                type: "success",
                message: __("Notification added successfully")
              })
            : this.$message({
                type: "success",
                message: __("Notification updated successfully")
              });
          EventBus.$emit("list-changed", data.data);
          this.handleCancel();
        })
        .catch(err => {
          console.log(err);
          this.isSubmitting = false;
          this.$message({
            type: "error",
            message: err.message
          });
        });
    },

    cancelForm() {
      this.handleCancel();
    },

    deleteSpNotification(contentFormInfo) {
      this.deleteNotification(contentFormInfo)
        .then(data => {
          EventBus.$emit("list-changed", data.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  },

  async created() {
    await this.getUsers({ fetch_all: 1 });
  },

  watch: {
    openModal: {
      immediate: true,
      handler() {
        this.$set(this.notificationPeriod, 0, this.contentForm.start_date);
        this.$set(this.notificationPeriod, 1, this.contentForm.end_date);
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .notification-form-dialog {
  max-height: 90vh !important;
}

::v-deep .el-dropdown-menu {
  width: 200px !important;
}

.createBtn {
  background-color: $content-theme-color;
  border-color: transparent;

  &:hover {
    background-color: $content-theme-hover-color;
    border-color: transparent;
  }
}

.cancelBtn {
  background-color: #ffffff;

  &:hover {
    color: $content-theme-hover-color;
    border-color: $content-theme-hover-color;
  }
}

.action-icon {
  height: 15px;
  width: 15px;
  padding-right: 20px;
}

.ql-container {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: inherit;
}
</style>
