<template>
  <div>
    <div class="notification-list">
      <div class="header">{{ __("Notifications") }}</div>
      <div class="body">
        <div
          class="item"
          v-for="notification in notifications"
          :key="notification.notification_message_id"
          @click="selectNotification(notification.notification_message_id)"
        >
          <el-row :gutter="20">
            <el-col :span="20">
              <div>
                <div class="title">{{ notification.notification_subject }}</div>
                <div>{{ formattedDateTime(notification.created_at) }}</div>
              </div>
            </el-col>
            <el-col :span="4" style="text-align: right;">
              <span
                class="unread-dot"
                v-if="notification.message_status === 'NEW'"
              ></span>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="footer">
        <el-button type="text" @click="viewAll" v-if="showViewAll">{{
          __("View all")
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    notifications: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      // openNotificationModal: false
    };
  },

  computed: {
    ...mapGetters("app", {
      formattedDateTime: "formattedDateTime"
    }),

    showViewAll() {
      return (
        this.$auth.user().roles[0] === "system" ||
        this.$auth.user().account_maps[0].scope === "sp"
      );
    }
  },

  methods: {
    viewAll() {
      if (this.$auth.user().roles[0] === "system") {
        this.$router.push("/system/notifications/");
      } else {
        this.$router.push("/manage/notifications");
      }
    },

    selectNotification(id) {
      this.$emit("open-modal", id, false);
    }
  }
};
</script>
