import { parseParams } from "@/utils/transformers";

/**
 * check the hostname for presence of a cloud train sector,
 * if present then:
 * 1. for backend, append to query string in url
 * 2. for socket server, update sector in socket url if doesn't match frontend url sector
 */
export function appendSector(hostname, envUrl, uri = "", queryObject = false) {
  let url = `${envUrl}${uri}`;

  if (!isCloudTrain(envUrl)) {
    return queryObject ? "" : url;
  }

  if (hasSectorInUrl(envUrl)) {
    return queryObject ? "" : url;
  }

  let sector = getHostnameSector(hostname);

  if (sector === "") {
    return queryObject ? "" : url;
  }

  return queryObject
    ? JSON.stringify({ sectorId: sector })
    : `${url}?sectorId=${sector}`;
}

function isCloudTrain(envUrl) {
  return envUrl.search("five9") !== -1;
}

function hasSectorInUrl(envUrl) {
  let sectorMatches = envUrl.match(/sec-[0-9]/);

  if (sectorMatches) {
    return true;
  }

  return false;
}
export function rewriteSocketSector(hostname, socketUrl) {
  if (!isCloudTrain(socketUrl)) {
    return socketUrl;
  }

  let sector = getHostnameSector(hostname);

  if (sector === "") {
    return socketUrl;
  }

  let sectorIndex = getSectorIndex(sector);

  // have a valid sector other than default then write out socket url
  if (parseInt(sectorIndex) > 1) {
    socketUrl = socketUrl.replace("sec-1", `sec-${sectorIndex}`);
  }

  return socketUrl;
}

function getHostnameSector(hostname) {
  // this handles cross launch scenario
  let localStorageSectorId = window.localStorage.getItem("sectorId");

  // present in local storage then cross domain login or IDP login
  if (localStorageSectorId && isValidSector(localStorageSectorId)) {
    return localStorageSectorId;
  }

  //if not present in local storage, then check the url for sector
  let hostnameParts = hostname.split(".");

  if (hostnameParts.length > 0) {
    let hostnamePart = hostnameParts[0];
    // match on sector prefix
    if (isValidSector(hostnamePart)) {
      return hostnamePart;
      // match on base url
    } else if (hostnamePart === "studio") {
      let alphaEnvironment = false;
      for (let i = 0; i < hostnameParts.length; i++) {
        if (hostnameParts[i] === "alpha") {
          alphaEnvironment = true;
        }

        // look for the two letter country code
        if (hostnameParts[i].length === 2) {
          let country = hostnameParts[i].match(/^[a-z]{2}$/);
          if (country) {
            // alpha has different default
            return `${country[0]}-${alphaEnvironment ? "a0" : "01"}`;
          }
          break;
        }
      }
    }
  }
  // no match found, return empty string
  return "";
}

function getSectorIndex(sector) {
  let sectorParts = sector.split("-");

  if (sectorParts.length === 2) {
    let sectorIndex = parseInt(sectorParts[1]);
    if (!isNaN(sectorIndex)) {
      return sectorIndex === 0 ? 1 : sectorIndex;
    }
  }

  return 1;
}

export function getSectorIdFromWindow() {
  try {
    if (Object.prototype.hasOwnProperty.call(window.location, "pathname")) {
      // if (window.location.hasOwnProperty("pathname")) {
      if (
        window.location.pathname === "/login/five9-sso" &&
        Object.prototype.hasOwnProperty.call(window.location, "hash")
        // window.location.hasOwnProperty("hash")
      ) {
        let data = parseParams(window.location.hash);
        if (
          // data.hasOwnProperty("sectorId") &&
          Object.prototype.hasOwnProperty.call(data, "sectorId") &&
          isValidSector(data.sectorId)
        ) {
          window.localStorage.setItem("sectorId", data.sectorId);
        }
      } else if (
        window.location.pathname === "/login" &&
        Object.prototype.hasOwnProperty.call(window.location, "search") &&
        // window.location.hasOwnProperty("search") &&
        window.location.search.includes("launch=okta")
      ) {
        // parse standard query string here
        const searchParams = new URLSearchParams(window.location.search);
        let sectorId = searchParams.get("sectorId");

        if (sectorId && isValidSector(sectorId)) {
          window.localStorage.setItem("sectorId", sectorId);
        } else {
          window.localStorage.removeItem("sectorId");
        }
      } else if (
        window.location.pathname === "/login" ||
        window.location.pathname === "/logout"
      ) {
        window.localStorage.removeItem("sectorId");
      }
    }
  } catch (e) {
    console.log("unable to parse window object " + e);
  }
}

function isValidSector(sector) {
  return (
    sector.match(/^[a-z]{2}-[0-9]{2}$/) || sector.match(/^[a-z]{2}-a[0-9]$/)
  );
}
