import Layout from "@/layout";
import __ from "@/translation";

const systemModuleRouter = {
  // define the route
  path: "/system",

  // this component describes the structure of the app after a user logs in. This component houses the sidebar and sets remaining area to populate contents of the child views
  component: Layout,

  redirect: "/", // when user tries to reach /system, it automatically tries to redirect to dashboard
  meta: {
    // meta data to pass additional information about the route
    auth: true, // this specifies that only logged in user can access this route. If someone tries to access this route, we will redirect to /login route
    // singleChild property is a custom information and is used in populating sidebar, to say that the child routes should be shown as sub menu to main menu 'Manage',
    // if it is true then first child will be shown as the main menu in the sidebar without any submenus.
    singleChild: false,
    icon: "icon-system.svg",
    roles: ["system"],
    // to do: replace with scope
    //scope: "system",
    get title() {
      return __("System");
    }, // label to show in the menu
    index: "sys-1" // unique index to identify the entry from the sidebar menu
  },
  children: [
    // list of all child routes
    // all the child routes will be prefixed by /system/
    /*{
      path: "migration",
      component: () => import("@/views/system/migration/index"),
      meta: {
        title: "Migration",
        icon: "icon-service-providers.svg",
        index: "sys-1-80"
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/migration/index"),
          name: "migration",
          meta: {
            title: "Portal Migration"
          },
          props: true,
          hidden: true
        }
      ]
    },*/
    {
      path: "service-providers",
      component: () => import("@/views/system/service-providers/index"),
      redirect: "/system/service-providers",
      meta: {
        get title() {
          return __("Service Providers");
        },
        icon: "icon-service-providers.svg",
        index: "sys-1-5",
        roles: ["system"],
        permissions: ["system.service-providers"]
      },
      children: [
        {
          path: "",
          component: () =>
            import("@/views/system/service-providers/pages/list"),
          name: "serviceProviders",
          meta: {
            get title() {
              return __("Service Providers");
            },
            permissions: ["system.service-providers"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "sys-telephony",
      component: () => import("@/views/system/sys-telephony/index"),
      meta: {
        get title() {
          return __("Sys Telephony");
        },
        icon: "icon-telephony.svg",
        index: "sys-1-4",
        roles: ["system"],
        permissions: ["system.sys-telephony"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/sys-telephony/index"),
          name: "sys-telephony",
          meta: {
            get title() {
              return __("Sys Telephony");
            },
            permissions: ["system.sys-telephony"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "sys-api-keys",
      component: () => import("@/views/system/sys-api-keys/index"),
      redirect: "/system/sys-api-keys",
      meta: {
        get title() {
          return __("Sys API Keys");
        },
        icon: "icon-api-key.svg",
        index: "sys-1-14",
        roles: ["system"],
        permissions: ["system.sys-api-keys"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/sys-api-keys/pages/list"),
          name: "sysapikeys",
          meta: {
            get title() {
              return __("Sys API Keys");
            },
            permissions: ["system.sys-api-keys"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "voices",
      component: () => import("@/views/system/voices/index"),
      redirect: "/system/voices",
      meta: {
        get title() {
          return __("Voices");
        },
        icon: "icon-voices.svg",
        index: "sys-1-8",
        roles: ["system"],
        permissions: ["system.voices"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/voices/pages/listVoices"),
          name: "Voices",
          meta: {
            get title() {
              return __("Voices");
            },
            permissions: ["system.voices"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "tts-packages",
      component: () => import("@/views/system/tts-packages/index"),
      redirect: "/system/tts-packages",
      meta: {
        get title() {
          return __("TTS Packages");
        },
        icon: "icon-tts-packages.svg",
        index: "sys-1-3",
        roles: ["system"],
        permissions: ["system.tts-packages"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/tts-packages/index"),
          name: "tts-packages",
          meta: {
            get title() {
              return __("TTS Packages");
            },
            permissions: ["system.tts-packages"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "asr-language-packages",
      component: () => import("@/views/system/asr-packages/index"),
      redirect: "/system/asr-packages",
      meta: {
        get title() {
          return __("Language Packages");
        },
        icon: "icon-tts-packages.svg",
        index: "sys-1-18",
        roles: ["system"],
        permissions: ["system.asr-language-packages"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/asr-packages/index"),
          name: "asr-language-packages",
          meta: {
            get title() {
              return __("Language Packages");
            },
            permissions: ["system.asr-language-packages"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "node-types",
      component: () => import("@/views/system/node-types/index"),
      redirect: "/system/node-types",
      meta: {
        get title() {
          return __("Node Types");
        },
        icon: "icon-nodes.svg",
        index: "sys-1-9",
        roles: ["system"],
        permissions: ["system.node-types"]
      },
      children: [
        {
          path: "",
          component: () =>
            import("@/views/system/node-types/pages/listNodeTypes"),
          name: "node-types",
          meta: {
            get title() {
              return __("Node Types");
            },
            permissions: ["system.node-types"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "business-plans",
      component: () => import("@/views/billing/business-plans/index"),
      redirect: "/system/business-plans",
      meta: {
        get title() {
          return __("Business Plans");
        },
        icon: "icon-business-plans.svg",
        index: "sys-1-10",
        roles: ["system"],
        permissions: ["system.business-plans"]
      },
      children: [
        {
          path: "",
          component: () =>
            import("@/views/billing/business-plans/pages/listBusinessPlans"),
          name: "business-plans",
          meta: {
            get title() {
              return __("Business Plans");
            },
            permissions: ["system.business-plans"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "sp-plans",
      component: () => import("@/views/billing/sp-plans/index"),
      redirect: "/system/sp-plans",
      meta: {
        get title() {
          return __("Service Provider Plans");
        },
        icon: "icon-service-provider-plans.svg",
        index: "sys-1-11",
        roles: ["system"],
        permissions: ["system.service-provider-plans"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/billing/sp-plans/pages/list"),
          name: "sp-plans",
          meta: {
            get title() {
              return __("Service Provider Plans");
            },
            permissions: ["system.service-provider-plans"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "regions",
      component: () => import("@/views/system/regions/index"),
      redirect: "/regions/regions",
      meta: {
        get title() {
          return __("Regions");
        },
        icon: "icon-regions.svg",
        index: "sys-1-10",
        roles: ["system"],
        permissions: ["system.regions"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/regions/pages/listRegion"),
          name: "regions",
          meta: {
            get title() {
              return __("Regions");
            },
            permissions: ["system.regions"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "payment-gateways",
      component: () => import("@/views/manage/payment/payment-gateways/index"),
      meta: {
        get title() {
          return __("Payment Gateways");
        },
        icon: "icon-payment-profile.svg",
        index: "sys-1-6",
        roles: ["system"],
        permissions: ["system.payment-gateways"]
      },
      children: [
        {
          path: "",
          component: () =>
            import("@/views/manage/payment/payment-gateways/pages/list"),
          name: "paymentgateways",
          meta: {
            get title() {
              return __("Payment Gateways");
            },
            permissions: ["system.payment-gateways"]
          },
          props: true,
          hidden: true
        }
      ]
    },

    {
      path: "sms-providers",
      component: () => import("@/views/system/sms-providers/index"),
      meta: {
        get title() {
          return __("SMS Providers");
        },
        icon: "icon-email-profiles.svg",
        index: "sys-1-7",
        roles: ["system"],
        permissions: ["system.sms-providers"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/sms-providers/pages/list"),
          name: "smsproviders",
          meta: {
            get title() {
              return __("SMS Providers");
            },
            permissions: ["system.sms-providers"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "dashboard-contents",
      component: () => import("@/views/system/announcements/index"),
      meta: {
        get title() {
          return __("Dashboard Contents");
        },
        icon: "icon-dashboard-contents.svg",
        index: "sp-1-18",
        roles: ["system"],
        permissions: ["system.dashboard-contents"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/announcements/index"),
          name: "dashboard-contents",
          meta: {
            get title() {
              return __("Dashboard Contents");
            },
            permissions: ["system.dashboard-contents"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "settings",
      component: () => import("@/views/system/settings/index"),
      meta: {
        get title() {
          return __("Settings");
        },
        icon: "icon-settings.svg",
        index: "sys-1-13",
        roles: ["system"],
        permissions: ["system.settings"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/settings/index"),
          name: "settings",
          meta: {
            get title() {
              return __("Settings");
            },
            permissions: ["system.settings"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "loaded-configuration",
      component: () => import("@/views/system/configuration/index"),
      meta: {
        get title() {
          return __("Loaded Configuration");
        },
        icon: "icon-settings.svg",
        index: "sys-1-13",
        roles: ["system"],
        permissions: ["system.loaded-configurations"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/configuration/index"),
          name: "loaded-configuration",
          meta: {
            get title() {
              return __("Loaded Configuration");
            },
            permissions: ["system.loaded-configurations"]
          },
          props: true,
          hidden: true
        }
      ]
    },

    {
      path: "notifications",
      component: () => import("@/views/system/notifications/index"),
      meta: {
        get title() {
          return __("Sys Notifications");
        },
        icon: "icon-notification-bell.svg",
        index: "sys-1-13",
        roles: ["system"],
        permissions: ["system.notifications"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/notifications/index"),
          name: "notifications",
          meta: {
            get title() {
              return __("Sys Notifications");
            },
            permissions: ["system.notifications"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "all-accounts",
      component: () => import("@/views/system/all-accounts/index"),
      redirect: "/system/all-accounts",
      meta: {
        get title() {
          return __("All Accounts");
        },
        icon: "icon-accounts.svg",
        index: "sys-1-12",
        roles: ["system"],
        permissions: ["system.all-accounts"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/all-accounts/index"),
          name: "all-accounts",
          meta: {
            get title() {
              return __("All Accounts");
            },
            permissions: ["system.all-accounts"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      // Need to add permission validation
      path: "move-account",
      component: () => import("@/views/system/move-account/index"),
      meta: {
        get title() {
          return __("Move Account");
        },
        icon: "icon-users.svg",
        index: "sys-1-16",
        roles: ["system"],
        permissions: []
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/move-account/index"),
          name: "move-account",
          meta: {
            get title() {
              return __("Move Account");
            },
            permissions: []
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "roles",
      component: () => import("@/views/system/roles/index"),
      meta: {
        get title() {
          return __("Roles");
        },
        icon: "icon-users.svg",
        index: "sys-1-15",
        roles: ["system"],
        permissions: ["system.roles"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/roles/pages/list"),
          name: "roles",
          meta: {
            get title() {
              return __("Roles");
            },
            permissions: ["system.roles"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "permissions",
      component: () => import("@/views/system/permissions/index"),
      meta: {
        get title() {
          return __("Permissions");
        },
        icon: "icon-users.svg",
        index: "sys-1-17",
        roles: ["system"],
        permissions: ["system.permissions"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/permissions/pages/list"),
          name: "permissions",
          meta: {
            get title() {
              return __("Permissions");
            },
            permissions: ["system.permissions"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      path: "openforms",
      component: () => import("@/views/system/openformtypes/index"),
      meta: {
        get title() {
          return __("Sys Open Form Types");
        },
        icon: "open_form.svg",
        index: "sys-1-19",
        roles: ["system"],
        permissions: ["system.sys-open-forms"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/openformtypes/pages/list"),
          name: "openforms",
          meta: {
            get title() {
              return __("Openforms");
            },
            permissions: ["system.sys-open-forms"]
          },
          props: true,
          hidden: true
        }
      ]
    },
    {
      // Need to add permission validation
      path: "migrate-account",
      component: () => import("@/views/system/migrate-account/index"),
      meta: {
        get title() {
          return __("Migrate Account");
        },
        icon: "icon-users.svg",
        index: "sys-1-20",
        roles: ["system"],
        permissions: []
      },
      children: [
        {
          path: "",
          component: () => import("@/views/system/migrate-account/index"),
          name: "migrate-account",
          meta: {
            get title() {
              return __("Migrate Account");
            },
            permissions: []
          },
          props: true,
          hidden: true
        }
      ]
    },
    // {
    //   path: "invoices",
    //   component: () => import("@/views/billing/invoices/index"),
    //   meta: {
    //     title: "Invoices",
    //     icon: "icon-payment-profile.svg",
    //     index: "sys-1-21",
    //     roles: ["system"],
    //     permissions: []
    //   },
    //   children: [
    //     {
    //       path: "",
    //       component: () => import("@/views/billing/invoices/index"),
    //       name: "list-invoices",
    //       meta: {
    //         title: "Invoices",
    //         permissions: []
    //       },
    //       props: true,
    //       hidden: true
    //     }
    //   ]
    // },
    {
      path: "burst-usages",
      component: () => import("@/views/billing/burst-usage/index"),
      meta: {
        title: "Burst Usages",
        icon: "icon-payment-profile.svg",
        index: "sys-1-21",
        roles: ["system"],
        permissions: ["system.burst-usages"]
      },
      children: [
        {
          path: "",
          component: () => import("@/views/billing/burst-usage/index"),
          name: "list-burst-usages",
          meta: {
            title: "Burst Usages",
            permissions: ["system.burst-usages"]
          },
          props: true,
          hidden: true
        }
      ]
    }
  ]
};

export default systemModuleRouter;
