import socketIO from "socket.io-client";
import { rewriteSocketSector } from "@/utils/url";

export function socketOptions() {
  ///update socket server url here, rewrite sector
  let options = {
    broadcaster: "socket.io",
    host: rewriteSocketSector(
      window.location.hostname,
      process.env.VUE_APP_SOCKET_SERVER
    ),
    client: socketIO,
    transports: ["websocket", "polling", "flashsocket"]
  };

  /*
   * for cloud train need to specify the path separately otherwise ws server
   * thinks a namespace is present if added to host
   */
  if (
    process.env.VUE_APP_SOCKET_SERVER_PATH !== undefined &&
    typeof process.env.VUE_APP_SOCKET_SERVER_PATH === "string" &&
    process.env.VUE_APP_SOCKET_SERVER_PATH !== ""
  ) {
    options.path = process.env.VUE_APP_SOCKET_SERVER_PATH;
  }

  return options;
}
