<template>
  <div>
    <el-popover placement="top" width="400" trigger="click">
      <div>
        <notification-popup
          :notifications="userNotifications"
          @open-modal="handleOpenModal"
        ></notification-popup>
      </div>
      <el-button slot="reference" type="text">
        <template>
          <img :src="getNotificationIcon" alt="" />
        </template>
      </el-button>
    </el-popover>

    <el-dialog title="Notification" :visible.sync="openNotificationModal">
      <notification-modal
        :notifications="userNotifications"
        :selected-notification-id="selectedNotificationId"
        :show-all-notifications="showAll"
        @close-modal="handleCloseModal"
      ></notification-modal>
    </el-dialog>
  </div>
</template>

<script>
import NotificationPopup from "@/views/system/notifications/components/notificationPopup";
import NotificationModal from "@/views/system/notifications/components/notificationModal";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    NotificationPopup,
    NotificationModal
  },

  data() {
    return {
      openNotificationModal: false,
      selectedNotificationId: null,
      showAll: true
    };
  },

  computed: {
    ...mapState("notifications", {
      notifications: state => state.notifications,
      userNotifications: state => state.userNotifications
    }),

    getNotificationIcon() {
      const unreadNotifications = this.userNotifications.filter(
        notif => notif.message_status === "NEW"
      );
      if (unreadNotifications && unreadNotifications.length) {
        return require("@/assets/icons/sidebar/icon-notification-bell-unread.svg");
      } else {
        return require("@/assets/icons/sidebar/icon-notification-bell.svg");
      }
    }
  },

  methods: {
    ...mapActions("notifications", {
      getUserNotifications: "getUserNotifications"
    }),

    handleOpenModal(selectedNotificationId, showAll) {
      this.selectedNotificationId = selectedNotificationId;
      this.showAll = showAll;
      this.openNotificationModal = true;
    },

    handleCloseModal() {
      this.openNotificationModal = false;
    }
  },

  async created() {
    await this.getUserNotifications();
    const unreadNotifications = this.userNotifications.filter(
      notif => notif.message_status === "NEW"
    );
    if (unreadNotifications && unreadNotifications.length) {
      this.openNotificationModal = true;
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;

::v-deep .notification-list {
  margin: -12px;

  .header {
    text-align: center;
    padding: 15px;
  }

  .body {
    .item {
      padding: 20px 30px;
      cursor: pointer;
      .title {
        font-weight: bold;
        line-height: 25px;
      }

      &:hover {
        .title {
          color: $content-theme-color;
        }
      }

      .unread-dot {
        height: 6px;
        width: 6px;
        background-color: $content-theme-color;
        border-radius: 50%;
        display: inline-block;
      }
    }
  }

  .footer {
    text-align: center;
    border-top: #f5f5f8 1px solid;
  }
}

::v-deep .notification-modal {
  .el-collapse,
  .el-collapse-item__header,
  .el-collapse-item__wrap {
    border: none;
  }

  .el-collapse-item__arrow {
    color: transparent;
  }

  .el-collapse-item {
    &.is-active {
      .title {
        color: $content-theme-color;
      }
    }

    .content {
      padding: 20px;
    }
  }
}

::v-deep .el-dialog__header {
  text-align: center;
}

::v-deep .el-button {
  ::v-deep object {
    pointer-events: none;
  }
}
</style>
