import request, { sp_headers } from "@/utils/studio7ApiService";

export function getTrunks(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/portal/trunks?" + params.toString(),
    method: "get",
    headers: sp_headers()
  });
}

export function getAllTrunks(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/portal/trunks?" + params.toString(),
    method: "get",
    headers: { scope: "sp", "scope-id": "all" }
  });
}

export function createTrunk(data) {
  const trunk = { ...data, guard_name: "admin" };
  return request({
    url: "/portal/trunks",
    method: "post",
    data: trunk,
    headers: sp_headers()
  });
}

export function updateTrunk(trunk) {
  return request({
    url: `/portal/trunks/${trunk.trunk_id}`,
    method: "put",
    data: trunk,
    headers: sp_headers()
  });
}

export function deleteTrunk(trunk) {
  return request({
    url: `/portal/trunks/${trunk.trunk_id}`,
    method: "delete",
    data: trunk,
    headers: sp_headers()
  });
}

export function registerTrunk(trunkRegistration) {
  return request({
    url: `/portal/trunk-registrants`,
    method: "post",
    data: trunkRegistration,
    headers: sp_headers()
  });
}

export function updateRegistration(trunkRegistration) {
  return request({
    url: `/portal/trunk-registrants/${trunkRegistration.trunk_registrant_id}`,
    method: "put",
    data: trunkRegistration,
    headers: sp_headers()
  });
}

export function getSBCIps() {
  return request({
    url: "/portal/sbc_ips",
    method: "get",
    headers: sp_headers()
  });
}

export function checkUniqueName(trunk_name) {
  return request({
    url: `/portal/validate-trunk-name/${trunk_name}`,
    method: "get",
    headers: sp_headers()
  });
}
