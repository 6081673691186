import {
  loadInteractionsUrl,
  openVisualFormSession,
  submitVisualFormSession,
  closeVisualFormSession
} from "@/api/nodes";
import { ac_headers } from "@/utils/studio7ApiService";
//import app from "./app"

const state = {
  message: "",
  contentId: null,
  submitted: false,
  interactionsUrl: ""
};

export const actions = {
  /**
   * get the interactions url, required for all subsequent api calls!
   * @param commit
   * @param dispatch
   * @returns {*}
   */
  loadInteractionsUrl({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      loadInteractionsUrl()
        .then(({ data }) => {
          commit("SET_INTERACTIONS_URL", data.url);
          resolve();
        })
        .catch(error => {
          setTimeout(() => {
            dispatch("app/toggleOptionsLoading", false, { root: true });
            commit("SET_MESSAGE", error.message);
          }, 500);
          reject();
        });
    });
  },

  /**
   * set the interactions url
   * @param commit
   * @param url
   */
  setInteractionsUrl({ commit }, url) {
    commit("SET_INTERACTIONS_URL", url);
  },

  /**
   * api call to open, triggers the visual form opened event
   * @param commit
   * @param dispatch
   * @param url
   * @param call_uuid
   * @returns {*}
   */
  openVisualForm({ commit, dispatch }, { url, call_uuid }) {
    return openVisualFormSession(url, {
      data: { call_uuid: call_uuid, ac_headers: ac_headers() }
    })
      .then(() => {
        // visual form opened will trigger data load through websocket, nothing more to do here
      })
      .catch(error => {
        setTimeout(() => {
          dispatch("app/toggleOptionsLoading", false, { root: true });
          commit("SET_MESSAGE", error.message);
        }, 500);
      });
  },

  /**
   * make an api call to submit the visual form and update local and global loading state
   * @param commit
   * @param dispatch
   * @param url
   * @param data
   * @returns {*}
   */
  submitVisualForm({ commit, dispatch }, { url, data }) {
    this.contentId = data.payload.button;
    dispatch("app/toggleOptionsLoading", true, { root: true });
    commit("SET_SUBMITTED", true);

    return submitVisualFormSession(url, data)
      .then(({ data }) => {
        commit("SET_MESSAGE", data.message);
        setTimeout(() => {
          dispatch("app/toggleOptionsLoading", false, { root: true });
        }, 500);
      })
      .catch(error => {
        setTimeout(() => {
          dispatch("app/toggleOptionsLoading", false, { root: true });
          commit("SET_MESSAGE", error.message);
        }, 500);
      });
  },

  /**
   * api call to close
   * @param commit
   * @param dispatch
   * @param url
   * @param data
   * @param uuid
   */
  closeVisualForm({ commit, dispatch }, { url, data }) {
    return closeVisualFormSession(url, data)
      .then(() => {
        //nothing more to do here
      })
      .catch(error => {
        setTimeout(() => {
          dispatch("app/toggleOptionsLoading", false, { root: true });
          commit("SET_MESSAGE", error.message);
        }, 500);
      });
  },

  /**
   * set submitted
   * @param commit
   * @param submitted
   */
  setSubmitted({ commit }, submitted) {
    commit("SET_SUBMITTED", submitted);
  },

  /**
   * set message
   * @param commit
   * @param message
   */
  setMessage({ commit }, message) {
    commit("SET_MESSAGE", message);
  }
};

export const mutations = {
  /**
   * set message
   * @param state
   * @param message
   * @constructor
   */
  SET_MESSAGE(state, message) {
    state.message = message;
  },
  /**
   * set submitted
   * @param state
   * @param submitted
   * @constructor
   */
  SET_SUBMITTED(state, submitted) {
    state.submitted = submitted;
  },
  /**
   * set interaction url
   * @param state
   * @param interactionsUrl
   * @constructor
   */
  SET_INTERACTIONS_URL(state, interactionsUrl) {
    state.interactionsUrl = interactionsUrl;
  }
};

export const getters = {
  message: state => state.message,
  submitted: state => state.submitted,
  contentId: state => state.contentId,
  interactionsUrl: state => state.interactionsUrl
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
