<template>
  <div class="notification-modal notification-list">
    <el-collapse
      v-model="selectedId"
      accordion
      class="body"
      @change="read"
      :key="componentKey"
    >
      <el-collapse-item
        v-for="notification in notificationsToShow"
        :key="notification.notification_message_id"
        :name="notification.notification_message_id"
        class="item"
      >
        <template slot="title">
          <el-row :gutter="20" style="width: 100%;">
            <el-col :span="20">
              <div>
                <div class="title">
                  {{ notification.notification_subject }}
                </div>
                <div style="line-height: 25px;">
                  {{ formattedDateTime(notification.created_at) }}
                </div>
              </div>
            </el-col>
            <el-col :span="4" style="text-align: right;">
              <span
                class="unread-dot"
                v-if="notification.message_status === 'NEW'"
              ></span>
              <el-button
                type="primary"
                @click="editNotification(notification)"
                v-if="showEditButton && notification.message_status !== 'NEW'"
                >{{ __("Forward") }}</el-button
              >
            </el-col>
          </el-row>
        </template>
        <div class="content">
          <p v-html="notificationContent(notification.content)"></p>
          <el-button
            type="primary"
            v-if="filterNotificationLink(notification.content) !== ''"
            @click="openLink(notification.content)"
            >{{ __("view report") }}</el-button
          >
        </div>
      </el-collapse-item>
    </el-collapse>
    <create-or-edit
      :content="selectedContent"
      :form-action="`edit`"
      :open-edit-model-prop="openEditModal"
      :show-info-panel="false"
      v-if="openEditModal"
      :template-prop="template"
      @handleCancel="openEditModal = false"
    >
    </create-or-edit>
    <div
      class="modal-actions"
      :class="[showAll ? 'justify-end' : 'justify-between']"
    >
      <el-button
        type="text"
        class="view-all-btn"
        @click="showAll = true"
        v-if="!showAll"
      >
        {{ __("View all notifications") }}
      </el-button>
      <el-button type="text" @click="handleClose">{{ __("Done") }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { marked } from "marked";
import CreateOrEdit from "@/views/manage/notifications/pages/createOrEdit";

export default {
  components: {
    CreateOrEdit
  },
  props: {
    notifications: {
      type: Array,
      default: () => []
    },
    selectedNotificationId: {
      default: null
    },
    showAllNotifications: {
      default: true
    }
  },

  data() {
    return {
      selectedContent: this.initContent(),
      selectedId: this.selectedNotificationId,
      showAll: this.showAllNotifications,
      componentKey: 0,
      openEditModal: false,
      template: null
    };
  },

  computed: {
    ...mapGetters("app", {
      formattedDateTime: "formattedDateTime"
    }),

    notificationsToShow() {
      return this.showAll
        ? this.notifications
        : this.notifications.filter(
            notif =>
              notif.notification_message_id === this.selectedNotificationId
          );
    },
    showEditButton() {
      return this.$auth.user().account_maps[0].scope === "sp";
    }
  },

  methods: {
    ...mapActions("notifications", {
      readNotification: "readNotification"
    }),

    filterNotificationLink(notification) {
      const splitString = notification.match(/\bhttps?:\/\/\S+/gi);
      if (splitString !== null) {
        return splitString;
      }
      return "";
    },

    openLink(notification) {
      const notificationContent = notification;
      const redirectLink = this.filterNotificationLink(notificationContent);
      if (redirectLink !== "") {
        this.$router
          .push({
            name: "reports",
            params: {
              "active-tab": "report-instances",
              job: `${redirectLink[0].split("/").at(-1)}`
            }
          })
          .catch(() => {});
      } else {
        this.$router.push("/analyse/reports").catch(() => {});
      }
      this.$emit("close-modal");
    },

    handleClose() {
      this.showAll = false;
      this.$emit("close-modal");
    },

    initContent() {
      return {
        notification_subject: "",
        content: "",
        target_user_group: "all_users",
        start_date: "",
        end_date: "",
        recipients: []
      };
    },

    read(id) {
      if (id && id > 0) {
        const notification = this.notifications.find(
          notif => notif.notification_message_id === id
        );
        if (notification && notification.message_status === "NEW") {
          this.readNotification({
            notificationMessageId: id,
            userId: this.$auth.user().user_id
          });
        }
      }
    },
    notificationContent(content) {
      if (content.match(/\bhttps?:\/\/\S+/gi) !== null) {
        return marked(content.replace(content.match(/\bhttps?:\/\/\S+/gi), ""));
      } else {
        return marked(content);
      }
    },

    editNotification(notification) {
      this.selectedContent = notification;
      this.openEditModal = true;
    }
  },

  watch: {
    selectedNotificationId: function(val, oldVal) {
      this.componentKey++;
      this.selectedId = val;
      if (val && val !== oldVal) {
        this.read(val);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.modal-actions {
  padding: 0 30px;
  display: flex;

  &.justify-between {
    justify-content: space-between;
  }

  &.justify-end {
    justify-content: flex-end;
  }

  .el-button {
    color: #454545;
    font-weight: 500;
  }

  .view-all-btn {
    text-decoration: underline;
  }
}
</style>
