import request, { sp_headers, ac_headers } from "@/utils/studio7ApiService";

export function getAccountsForServiceProvider(options = { fetch_all: 1 }) {
  const params = new URLSearchParams(options);
  return request({
    url: "/portal/accounts?" + params.toString(),
    method: "get",
    ...(!options.system_access && { headers: sp_headers() })
  });
}

export function createAccount(data) {
  const account = { ...data, guard_name: "admin" };
  return request({
    url: "/portal/accounts",
    method: "post",
    data: account,
    headers: sp_headers()
  });
}

export function updateAccount(account) {
  return request({
    url: `/portal/accounts/${account.ac_id}`,
    method: "put",
    data: account,
    headers: sp_headers()
  });
}

export function deleteAccount(account) {
  return request({
    url: `/portal/accounts/${account.ac_id}`,
    method: "delete",
    data: account,
    headers: sp_headers()
  });
}

export function forceDeleteAccount(account) {
  return request({
    url: `/portal/force-delete/${account.ac_id}`,
    method: "post",
    data: account,
    headers: sp_headers()
  });
}

export function bulkDeleteAccounts(accounts) {
  return request({
    url: "/portal/bulk-delete-accounts",
    method: "post",
    data: accounts,
    headers: sp_headers()
  });
}

// export function addApiKey(account) {
//   return request({
//     url: `/portal/create_api_key/${account.ac_id}`,
//     method: "post",
//     headers: sp_headers()
//   });
// }

export function checkUniqueName(ac_name) {
  return request({
    url: `/portal/validate-ac-name`,
    method: "post",
    data: { ac_name },
    headers: sp_headers()
  });
}

export function checkUniqueDomain(domain_id) {
  return request({
    url: `/portal/validate-ac-domain`,
    method: "post",
    data: { domain_id },
    headers: sp_headers()
  });
}

export function getDomainInfo(domainId) {
  return request({
    url: `/portal/getDomainInfo?domain_id=${domainId}`,
    method: "get",
    headers: sp_headers()
  });
}

export function getDomainSkills(domainId) {
  return request({
    url: `/portal/getDomainSkills?domain_id=${domainId}`,
    method: "get",
    headers: ac_headers()
  });
}

/**
 * get account accessible tts voices
 * @returns A list of tts voices that the selected account has access to
 */
export function getAccountTtsVoices() {
  return request({
    url: "portal/getAccountTtsVoices",
    method: "get",
    headers: ac_headers()
  });
}

export function getAccountsBySpId(sp_id) {
  const params = new URLSearchParams({ account_enabled: 1 });
  let header = { ...sp_headers() };
  header["scope-id"] = sp_id;
  return request({
    url: "/portal/accounts?" + params.toString(),
    method: "get",
    headers: header
  });
}

export function getAccountData(ac_id) {
  return request({
    url: `/portal/accounts/${ac_id}`,
    method: "get",
    headers: ac_headers()
  });
}

export function getKnowledgeGroups(token) {
  const headers = {
    ...ac_headers(),
    Authorization: `Bearer ${token}`
  };
  return request({
    url: "/knowledge-distiller/knowledge-groups",
    method: "get",
    headers: headers
  });
}

export function flushCache(ac_id) {
  return request({
    url: `/portal/clear-account-cache`,
    method: "post",
    data: { ac_ids: [ac_id] }
  });
}
