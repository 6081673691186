import request, { ac_headers } from "@/utils/studio7ApiService";

const EVENT_SUBMIT = "submit";
const EVENT_OPEN = "open";
const EVENT_CLOSE = "close";

export function getNodes({ ac_id, task_id, include }) {
  return request({
    url: `node/nodes?ac_id=${ac_id}&task_id=${task_id}&include=${include}`,
    method: "get",
    headers: ac_headers()
  });
}

export function getTaskForNode({ node_id }) {
  return request({
    url: `node/task/${node_id}`,
    method: "get",
    headers: ac_headers()
  });
}

export function createNode(
  node,
  canvasId,
  notSwap = true,
  attemptConvertSameNodeToGoTo = false,
  connectorCondition = ""
) {
  return request({
    url: "/node/nodes?include=node_type",
    method: "post",
    data: {
      ...node,
      canvas_id: canvasId,
      swap: !notSwap,
      same_node: attemptConvertSameNodeToGoTo,
      connector_condition: connectorCondition
    },
    headers: ac_headers()
  });
}

export function editNode(
  node,
  canvasId,
  notSwap = true,
  attemptConvertSameNodeToGoTo = false,
  connectorCondition = ""
) {
  return request({
    url: `/node/nodes/${node.node_id}?include=node_type`,
    method: "put",
    data: {
      ...node,
      canvas_id: canvasId,
      swap: !notSwap,
      same_node: attemptConvertSameNodeToGoTo,
      connector_condition: connectorCondition
    },
    headers: ac_headers()
  });
}

export function deleteNode(nodeId, deleteType, canvasId) {
  return request({
    url: `/node/nodes/${nodeId}?include=node_type`,
    method: "delete",
    data: { delete_type: deleteType, canvas_id: canvasId },
    headers: ac_headers()
  });
}

export function unlinkNode(data) {
  return request({
    url: `/node/unlink?include=node_type`,
    method: "post",
    data,
    headers: ac_headers()
  });
}

export function pasteNode(data) {
  return request({
    url: `node/copy?include=node_type`,
    method: "post",
    data,
    headers: ac_headers()
  });
}

// requests for canvases

export function getCanvases({ ac_id, task_id }) {
  return request({
    url: `/node/canvas?ac_id=${ac_id}&task_id=${task_id}`,
    method: "get",
    headers: ac_headers()
  });
}

export function createCanvas({ ac_id, task_id }) {
  return request({
    url: `/node/canvas?include=node_type`,
    method: "post",
    data: { ac_id, task_id },
    headers: ac_headers()
  });
}

export function editCanvas(canvas) {
  return request({
    url: `/node/canvas/${canvas.canvas_id}`,
    method: "put",
    data: canvas,
    headers: ac_headers()
  });
}

export function accountNodeTypes({ task_type }) {
  return request({
    url: "/billing/ac-node-types",
    method: "post",
    data: { task_type },
    headers: ac_headers()
  });
}

export function deleteCanvas(canvas) {
  return request({
    url: `/node/canvas/${canvas.canvas_id}`,
    method: "delete",
    data: canvas,
    headers: ac_headers()
  });
}

export function validateLinkTaskIdForRecursion(from_task_id, to_task_id) {
  return request({
    url: `/node/validate-task-id-for-recursion`,
    method: "post",
    data: { from_task_id, to_task_id },
    headers: ac_headers()
  });
}

/**
 * open visual form page
 * @param interactionsUrl
 * @param data
 * @returns {*}
 */
export function submitVisualFormSession(interactionsUrl, data) {
  data.event = EVENT_SUBMIT;
  // update url when ready
  return request({
    url: `${interactionsUrl}/api/visual-form/${data.call_uuid}/event`,
    method: "post",
    data: data,
    headers: data.ac_headers
  });
}

/**
 * open visual form page
 * @param interactionsUrl
 * @param data
 * @returns {*}
 */
export function openVisualFormSession(interactionsUrl, data) {
  // update url when ready
  data.data.event = EVENT_OPEN;
  return request({
    url: `${interactionsUrl}/api/visual-form/${data.data.call_uuid}/event`,
    method: "post",
    data: data.data,
    headers: data.ac_headers
  });
}

/**
 * close visual form page
 * @param interactionsUrl
 * @param data
 * @returns {*}
 */
export function closeVisualFormSession(interactionsUrl, data) {
  // update url when ready
  data.event = EVENT_CLOSE;
  const body = { ...data };
  const headers = {
    type: "application/json",
    ...data.ac_headers
  };
  const blob = new Blob([JSON.stringify(body)], headers);

  // Use beacon to reliably send async HTTP to interactions
  return new Promise((resolve, reject) => {
    const beaconSuccess = navigator.sendBeacon(
      `${interactionsUrl}/api/visual-form/${data.call_uuid}/event`,
      blob
    );

    if (beaconSuccess) {
      resolve(true);
    } else {
      reject(false);
    }
  });
}

/**
 * get interactions url
 * @param data
 * @returns {*}
 */
export function loadInteractionsUrl() {
  // update url when ready
  return request({
    url: `/interactions-domain`,
    method: "get"
  });
}

/**
 * Upload the user's attachment and get public URL + MIME type
 * @param {FormData} formRequest
 * @returns
 */
export function uploadVisualFormUserAttachment(formRequest) {
  return request({
    url: `/visualform/user-attachment`,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data: formRequest
  });
}
