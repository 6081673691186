<template>
  <div class="sidebar">
    <sidebar-settings></sidebar-settings>
    <el-scrollbar class="sidebar-menu" :native="false">
      <el-menu
        :default-active="activeRoute.path"
        class="el-menu-sidebar"
        :collapse="false"
      >
        <div v-for="route in visibleRoutes" :key="route.path">
          <el-menu-item
            v-if="!route.children"
            @click="handleNavigation(route)"
            :class="{ 'is-active': isMenuActive(route) }"
            :index="route.meta.index || String(Math.random())"
            :dusk="`sb-menu-${customiseDuskName(route)}`"
          >
            <span slot="title">{{ route.meta.title }}</span>
          </el-menu-item>
          <el-menu-item
            v-else-if="route.children && route.meta.singleChild"
            @click="handleNavigation(route.children[0])"
            :class="{ 'is-active': isMenuActive(route.children[0]) }"
            :index="route.children[0].meta.index"
            :dusk="`sb-menu-${customiseDuskName(route.children[0])}`"
          >
            <img
              v-if="route.children[0].meta.icon"
              :src="getSidebarIcon(route.children[0].meta.icon)"
              style="height: 24px; width: 24px; margin-right: 15px"
            />
            <span slot="title" style="font-size: 1rem">{{
              route.children[0].meta.title
            }}</span>
          </el-menu-item>
          <el-submenu
            v-else
            :index="route.meta.index || String(Math.random())"
            :dusk="`sb-submenu-${customiseDuskName(route)}`"
          >
            <template slot="title">
              <img
                v-if="route.meta.icon"
                :src="getSidebarIcon(route.meta.icon)"
                style="height: 24px; width: 24px; margin-right: 15px"
              />
              <span slot="title" style="font-size: 1rem">{{
                route.meta.title
              }}</span>
            </template>
            <div
              v-for="childRoute in route.children"
              :key="childRoute.path"
              class="relative"
            >
              <el-tooltip
                class="item"
                effect="dark"
                placement="bottom"
                :open-delay="500"
                :disabled="checkPermission(childRoute.meta)"
              >
                <div slot="content" style="white-space: pre-wrap;">
                  {{ tooltipMessage }}
                </div>
                <el-menu-item
                  @click="handleNavigation(childRoute)"
                  v-if="!childRoute.hidden"
                  :class="{ 'is-active': isMenuActive(childRoute) }"
                  :index="childRoute.meta.index || String(Math.random())"
                  class="double-border"
                  :dusk="`sb-submenu-${customiseDuskName(childRoute)}`"
                  :disabled="!checkPermission(childRoute.meta)"
                >
                  <span slot="title" style="font-size: 0.875rem">{{
                    childRoute.meta.title
                  }}</span>
                </el-menu-item>
              </el-tooltip>

              <add-button
                v-if="
                  isRouteFound(
                    getRoute(childRoute.children, { path: 'create' })
                  )
                "
                @handleAdd="
                  handleNavigation(
                    getRoute(childRoute.children, { path: 'create' })
                  )
                "
                :dusk-setting="`${customiseDuskName(childRoute)}-add-btn`"
              ></add-button>
            </div>
          </el-submenu>
        </div>
      </el-menu>
    </el-scrollbar>

    <div class="user-info-tab">
      <!--       todo user role information has to be decided-->
      <user-profile
        :user-info-secondary="truncate($auth.user().display_name || 'User')"
        :user-info-add-on="
          $store.getters.userRole ? $store.getters.userRole : 'All SPs'
        "
      ></user-profile>
      <div class="quick-btns">
        <div class="settings-btn" dusk="sb-settings-btn">
          <el-tooltip placement="top">
            <template #content>
              <div
                style="display:flex; align-self: stretch; justify-content: center;"
              >
                {{ __("Settings") }}
              </div>
            </template>
            <el-dropdown trigger="click" @command="handleDropDownItemClick">
              <div>
                <img :src="require('@/assets/icons/icon-settings.svg')" />
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="{ action: 'Profile' }"
                  dusk="sb-profile-btn"
                  v-if="!five9ManagedCurrentUser"
                >
                  {{ __("My Profile") }}
                </el-dropdown-item>

                <el-dropdown-item
                  :command="{ action: 'Logout' }"
                  dusk="sb-logout-btn"
                >
                  {{ __("Sign Out") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
        </div>
        <div class="settings-btn">
          <el-tooltip placement="top">
            <template #content>
              <div
                style="display:flex; align-self: stretch; justify-content: center;"
              >
                {{ __("Notifications") }}
              </div>
            </template>
            <sidebar-notification></sidebar-notification>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="app-version">version {{ this.frontEndReleaseVersion }}</div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SidebarSettings from "./SidebarSettings";
import AddButton from "./AddButton";
import _ from "lodash";
import store from "@/store";
import UserProfile from "@/layout/components/sidebar/UserProfile";
import SidebarNotification from "@/views/system/notifications/components/sidebarNotification";
import Translation from "@/mixins/translation";
import Five9AdminConsoleManagedUser from "@/mixins/Five9AdminConsoleManagedUser";

export default {
  name: "SidebarIndex",
  mixins: [Translation, Five9AdminConsoleManagedUser],
  components: {
    UserProfile,
    SidebarSettings,
    AddButton,
    SidebarNotification
  },
  data() {
    return {
      isCollapse: true,
      activeRoute: "",
      toolTipMsg:
        __("This feature is not provisioned") +
        "\n" +
        __("in your business plan")
    };
  },
  computed: {
    ...mapState("permission", {
      routes: state => state.routes
    }),
    ...mapState("loadedconfigurations", {
      contents: state => state.configurations
    }),
    isMenuActive() {
      return route => {
        return _.some(this.activeRoute.matched, {
          meta: { title: route.meta.title }
        });
      };
    },

    visibleRoutes() {
      return this.routes.filter(route => !route.hidden);
    },

    isRouteFound() {
      return route => !_.isEmpty(route);
    },

    tooltipMessage: {
      get: function() {
        return this.toolTipMsg;
      },

      set: function(newMsg) {
        this.toolTipMsg = newMsg;
      }
    },

    checkPermission() {
      return meta => {
        let permissions = meta.permissions;
        if (permissions && permissions.length) {
          let permission_to_validate = permissions[0].toLowerCase();
          let self = this;
          //system routes
          if (
            store.getters.userRole &&
            store.getters.userRole !== "system" &&
            store.getters.userRole.includes("system")
          ) {
            self.tooltipMessage =
              __("You do not have permission") +
              "\n" +
              __("to manage this item");
            return this.can(permission_to_validate);
          }
          //sp mode
          if (store.getters.userType === "sp") {
            self.tooltipMessage =
              __("You do not have permission") +
              "\n" +
              __("to manage this item");
            return this.can(permission_to_validate);
          }
          // ac mode
          else if (store.getters.selectedAccountId !== "all") {
            self.tooltipMessage =
              __("This feature is either not provisioned in your business") +
              "\n" +
              __("plan or you do not have permission to view it.");

            return this.can(permission_to_validate);
          } else {
            return true;
          }
        } else {
          return true;
        }
      };
    },

    frontEndReleaseVersion() {
      return process.env.VUE_APP_STUDIO_FRONTEND_TAG;
    }
  },

  methods: {
    ...mapActions("auth", { logout: "logout" }),

    getSidebarIcon(icon) {
      return require("@/assets/icons/sidebar/" + icon);
    },

    customiseDuskName(route) {
      let title = _.toLower(route.meta.title);
      return title.replace(/ /g, "-");
    },

    handleDropDownItemClick({ action }) {
      switch (action) {
        case "Logout":
          this.logout();
          break;
        case "Profile":
          this.$router.push("/manage/profile");
          break;
        default:
          break;
      }
    },

    handleNavigation(route) {
      const name = route.children ? route.children[0].name : route.name;
      this.$router.push({ name });
    },
    getRoute(routes, route) {
      return _.find(routes, route);
    },
    truncate(value, limit = 21) {
      if (value && value.length <= limit) {
        return value;
      } else if (value) {
        return value.substring(0, limit) + "...";
      } else {
        return value;
      }
    },

    /**
     * Load the translation language from backend according to the user's language preference
     */
    loadUserLanguage() {
      const user = this.$auth.user();
      if (user && user.language) {
        this.translateForUser({
          data: {
            language: user.language
          }
        });
      }
    }
  },

  mounted() {
    this.activeRoute = this.$route;
  },
  created() {
    this.loadUserLanguage();
  },
  watch: {
    $route: {
      handler: function(route) {
        this.activeRoute = route;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
.sidebar-menu {
  height: calc(100vh - 163px);
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}

.sidebar {
  .user-info-tab {
    /*padding-top: 2px;*/
    height: 58px;
    border-top: #f5f5f8 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .quick-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .settings-btn {
        margin-right: 10px;
        cursor: pointer;

        i {
          font-size: 24px;
        }
      }
    }

    .user-profile {
      margin: 0 !important;
      flex: 1;
      min-width: 0;
      ::v-deep .user-icon {
        margin-left: 5px;
      }

      ::v-deep .user-info {
        display: flex;
        height: 48px;
        flex-direction: column;
        flex: 0 1 auto;
        min-width: 2px;
        justify-content: flex-end;
        color: black;

        span {
          line-height: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.secondary-info {
            font-size: 1rem;
            padding-bottom: 2px;
          }

          &.add-on {
            color: $--color-text-placeholder;
            font-size: 0.9rem;
            padding-bottom: 5px;
          }
        }
      }
    }
  }

  .app-version {
    text-align: right;
    font-size: 12px;
    color: #b4b4b4;
    margin-right: 10px;
  }

  ::v-deep .el-menu-sidebar {
    border-right: none;

    &:not(.el-menu--collapse) {
    }

    .el-submenu {
      & [class^="el-icon-"] {
        margin-right: 15px;
      }

      .el-submenu__title {
        margin-left: 13px;
        font-weight: bold;

        &:hover {
          background: $--color-white;
        }
      }

      .el-menu-item {
        font-weight: 300;
        font-size: 1rem;
        color: #454545;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .el-menu-item {
      border-radius: 5px;
      margin-left: 13px;
      padding: 0 20px !important;
      font-weight: bold;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: -13px;
        right: 0;
        bottom: 0;
        pointer-events: none;
        width: 5px;
        border-radius: 10px;
        background-color: $--menu-background-color;
      }

      &:hover {
        &:before {
          background-color: $--menu-item-hover-fill;
        }
      }

      &.is-active {
        color: $--color-text-primary;
        background: $--menu-item-hover-fill;

        &:before {
          background-color: var(--theme-color);
        }
      }
    }
  }
}
</style>
